import React from 'react';
import styled from 'styled-components';
import { Button, BigText } from '../common';
import { InputField, InputRow, SelectField, TextArea } from './inputFields';

const Outer = styled.div`
    padding: 20px;
    max-width: 1100px;
    margin: auto;
`

const Wrapper = styled.form`
    max-width: 800px;
    width: 90%;
    margin: auto;
`
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px;
`
const Sbutton = styled.button`
    border: 0;
    padding: 0;
    background: 0;
`

const Index = () => {
    return (
        <Outer>
            <BigText>Kontaktformular</BigText>
            <Wrapper action="/success" name="Anfrageformular" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="Anfrageformular" />
                <InputRow>
                    <InputField required type="text" name="vorname" label="Vorname" />
                    <InputField required type="text" name="name" label="Nachname" />
                </InputRow>
                <InputRow>
                    <InputField required type="email" name="email" label="E-Mail" />
                    <SelectField />
                </InputRow>
                <InputRow single>
                    <TextArea />
                </InputRow>
                <ButtonWrapper>
                    <Sbutton type="submit">
                        <Button>
                            Absenden
                        </Button>
                    </Sbutton>
                </ButtonWrapper>
            </Wrapper>
        </Outer>
    )
}

export default Index;
