import React from 'react';
import styled from 'styled-components';

const InputRowWrap = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: ${props => props.single ? 'auto' : 'auto auto'};
    @media (max-width: 580px) {
        grid-template-columns: auto;
        grid-gap: 0;
    }
`

const InputWrapper = styled.div`
    padding: 10px;
`
const Input = styled.input`
    padding: 10px;
    margin: 5px 0;
    border: 0;
    background: ${props => props.theme.colors.gray1};
    font-size: 1.2em;
    width: 100%;
`
const Label = styled.label`
    margin-left: 5px;
    font-weight: 700;
`
const Select = styled.select`
    padding: 5px;
    margin: 5px 0;
`
const File = styled.input`
    padding: 5px;
    background: ${props => props.theme.colors.gray1};
    margin: 5px 0;
`
const STextArea = styled.textarea`
    padding: 10px;
    margin: 5px 0;
    border: 0;
    min-height: 180px;
    background: ${props => props.theme.colors.gray1};
    font-size: 1.2em;
    width: 100%;
    font-family: ${props => props.theme.fonts.normal};
`

const InputField = ({ label, name, type, required }) => (
    <InputWrapper>
        <Label htmlFor={name}>{label} {required ? '' : ' (optional)'}</Label>
        <div>
            <Input required={required} type={type} placeholder={label} name={name} />
        </div>
    </InputWrapper>
);

const SelectField = () => (
    <InputWrapper>
        <Label htmlFor="lieferTermin">Betreff</Label> 
        <div>
            <Select name="liefertermin">
                <option value="fussball">Fußball</option>
                <option value="fitness">Fitness & Gymnastik</option>
                <option value="kinderturnen">Kinderturnen</option>
                <option value="handicap">Sport mit Handicap</option>
                <option value="anmeldung">Anmeldung / Mitgliedsbeitrag</option>
            </Select>
        </div>
    </InputWrapper>
)

const FileField = () => (
    <InputWrapper>
        <Label htmlFor="dateiupload">Dateiupload (Step, Zeichnung) (optional)</Label> 
        <div>
            <File type="file" placeholder="Datei" name="dateiupload" />
        </div>
    </InputWrapper>
)

const InputRow = ({ children, single }) => (
    <InputRowWrap single={single}>
        {children}
    </InputRowWrap>
)

const TextArea = () => (
    <InputWrapper>
        <Label htmlFor="textfeld">Sonstiges (optional)</Label>
        <div>
            <STextArea placeholder="Was möchten Sie uns mitteilen?" name="textfeld" />
        </div>
    </InputWrapper>
)

export { InputField, InputRow, SelectField, FileField, TextArea };