import * as React from "react";
import SiteWrapper from '../components/siteWrapper';
import ContactForm from '../components/contact';
import Nav from '../components/menu/anfahrtNav';

const IndexPage = (props) => {
    return (
        <SiteWrapper title="Anfahrt - SV Kappel" description="Trete hier über das Kontaktformular in Kontakt mit dem Sporverein Kappel e.V. ">
            <Nav />
            <ContactForm />
        </SiteWrapper>
    );
}

export default IndexPage;
